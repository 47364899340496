$enable-negative-margins : true;

$spacer: 1rem;
$spacers: (
        0: 0,
        4: .4rem,
        8: .8rem,
        12: 1.2rem,
        16: 1.6rem,
        24: 2.4rem,
        32: 3.2rem,
        48: 4.8rem,
        106: 10.6rem,
);