
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$font-family-base: "Roboto", sans-serif;
$font-size-base : 1.8rem;

/* ======= font-size
https://getbootstrap.com/docs/5.2/utilities/text/#maps
*/
$font-sizes: ();

$font-sizes: (
		18: 1.8rem,
		70: 7rem,
);


/* ======= line height
*/

$line-height: ();
$line-height: (
		14: 1.4rem,
		16: 1.6rem,
		17: 1.7rem,
		18: 1.8rem,
		20: 2rem,
		24: 2.4rem,
);