// variables de bootstrap par défault
// https://getbootstrap.com/docs/5.1/utilities/colors/#variables
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #DFE1E6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #30305C;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$primary:       #1E3F41;
$secondary:     #30305C;

$bg: #C0AD7D;


$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
				(
						"primary":    $primary,
						"secondary":  $secondary,
						"bg":  $bg,
						"gray-300":  $gray-300,
						"red":  $red,
				  //"new-color":  $new-color, // permet de créer des class bootstrap complète de type btn-new-color, bg-new-color, text-new-color etc...
				),
				$theme-colors
);